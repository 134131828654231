import * as React from 'react'; 
import {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import { Box, Paper, useTheme, styled } from '@mui/material';
import { AspectRatio, Link, Card, Button, CardContent, Chip, Typography } from "@mui/joy";

const chipColors = {
    "Dev": "primary",
    "Data": "danger",
    "Utility": "success",
    "Graphics": "warning"
}
/*
function InteractiveCard1(props){
    const theme = useTheme(); 
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
      setIsClicked(!isClicked);
    };

    return (
        <Card
      variant="soft"
      orientation="vertical"
      sx={{
        '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
        bgcolor: theme.palette.background.card,
        "max-width": isClicked?"300px":"150px",
        padding: "10px",
        height: isClicked?"600px":"300px"
      }}
      onClick = {handleClick}
    >
      {props.icon}
      {props.children}
      <CardContent>
        <Typography level="title-lg" id="card-description" sx={{color: theme.palette.text.primary}}>
          {props.label}
        </Typography>
        <Typography level="body-sm" aria-describedby="card-description" mb={1}>
          <Link
            overlay
            underline="none"
            href={"#" + props.label}
            sx={{ color: 'text.tertiary', textAlign: "justify" }}
          >
            {props.desc}
          </Link>
        </Typography>
        {props.type&&
        <Chip
          variant="solid"
          color={chipColors[props.type]}
          size="sm"
          sx={{ pointerEvents: 'none' }}
        >
          {props.type}
        </Chip>}
      </CardContent>
    </Card>
);
}
function InteractiveCard(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery({query: `(max-width: 650px)`});

  return (
    <Card
      variant="soft"
      orientation="vertical"
      sx={{
        '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
        bgcolor: theme.palette.background.card,
        maxWidth: props.isClicked ? isMobile? '100%':'300px' : '150px',
        maxHeight: props.isClicked ? "600px" : "300px",
        padding: '10px',
        height: props.isClicked ? '600px' : '300px',
        transition: 'max-width 0.3s ease, height 0.3s ease',
        cursor: 'pointer',
        flexBasis: props.isClicked ? '70%' : 'calc(33.33% - 40px)',
        order: props.isClicked ? -1 : 'unset',

      }}
      onClick={props.onClick}
    >
      {props.icon}
      {props.children}
      <CardContent>
        <Typography level="title-lg" id="card-description" sx={{ color: theme.palette.text.primary }}>
          {props.label}
        </Typography>
        <Typography level="body-sm" aria-describedby="card-description" mb={1}>
          <Link
            overlay
            underline="none"
            href={"#" + props.label}
            sx={{ color: 'text.tertiary', textAlign: 'justify' }}
          >
            {props.desc}
          </Link>
        </Typography>
        {props.type && (
          <Chip
            variant="solid"
            color={chipColors[props.type]}
            size="sm"
            sx={{ pointerEvents: 'none' }}
          >
            {props.type}
          </Chip>
        )}
      </CardContent>
    </Card>
  );
}
*/
// Function to truncate text

/**
 * Adjust the card width to be more narrow.
 */

// Start Generation Here





const InteractiveCard = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery({query: `(max-width: 650px)`});
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const adjustCardOrder = (index) => {
    if (index % 3 === 0) {
      return 1; // First column
    } else if (index % 3 === 1) {
      return 2; // Second column
    } else {
      return 3; // Third column
    }
  };
  
  const adjustCardFlexBasis = (index, isClicked) => {
    if (isClicked) {
      return '70%';
    } else {
      if (index % 3 === 2) {
        return 'calc(33.33% - 40px)';
      } else {
        return 'calc(33.33% - 20px)';
      }
    }
  };
  
  const handleClick = () => {
    setIsClicked(!isClicked);
    if (!isClicked) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };
  
  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setIsClicked(true);
    }
  };
  
  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + '...';
  };
  
  const cardStyles = {
    '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
    bgcolor: theme.palette.background.card,
    maxWidth: props.isClicked ? isMobile ? '95%' : '300px' : '150px',
    maxHeight: isExpanded ? "800px" : props.isClicked ? "600px" : "300px",
    padding: '10px',
    height: isExpanded ? '800px' : props.isClicked ? '600px' : '300px',
    transition: 'max-width 0.3s ease, height 0.3s ease',
    cursor: 'pointer',
    flexBasis: props.isClicked ? '70%' : 'calc(33.33% - 40px)',
    order: props.isClicked ? -1 : 'unset',
  };


return (
  <Card
    variant="soft"
    orientation="vertical"
    sx={cardStyles}
    onClick={handleClick}
  >
    {props.icon}
    {props.children}
    <CardContent>
      <Typography level="title-lg" id="card-description" sx={{ color: theme.palette.text.primary }}>
        {props.label}
      </Typography>
      <Typography level="body-sm" aria-describedby="card-description" mb={1}>
        <Link
          overlay
          underline="none"
          href={"#" + props.label}
          sx={{ color: theme.palette.text.tertiary, textAlign: 'justify' }}
        >
          {isExpanded ? props.desc : truncateText(props.desc, 50)}
        </Link>
        {props.desc.length > 50 && (
          <Button
            onClick={handleReadMore}
            sx={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Typography
              component="span"
              sx={{
                color: theme.palette.text.secondary,
                cursor: 'pointer',
                marginLeft: theme.spacing(0.5),
                fontWeight: 'bold',
              }}
            >
              {isExpanded ? 'Read Less' : 'Read More'}
            </Typography>
          </Button>
        )}
      </Typography>
      {props.type && (
        <Chip
          variant="solid"
          color={chipColors[props.type]}
          size="sm"
          sx={{ pointerEvents: 'none' }}
        >
          {props.type}
        </Chip>
      )}
    </CardContent>
  </Card>
);
};





export default InteractiveCard; 