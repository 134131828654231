import { createTheme } from '@mui/material/styles';

const brightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ff9800', // vibrant orange
    },
    secondary: {
      main: '#ffb74d', // soft orange
    },
    background: {
      main: '#ffffff', // white
      default: '#fff3e0', // very light orange
      paper: '#ffe0b2', // light orange
      card: '#ffcc80', // lighter orange
      bar: '#ffb74d', // soft orange
    },
    text: {
      primary: '#212121', // dark grey
      secondary: '#757575', // medium grey
      tertiary: '#ff9800', // vibrant orange
    },
    shadow: {
      accordion: '#e0e0e0', // light grey
    },
    button: {
      main: '#ff9800', // vibrant orange
      secondary: '#ffb74d', // soft orange
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const adarkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1e88e5', // vibrant blue
    },
    secondary: {
      main: '#42a5f5', // soft blue
    },
    background: {
      main: '#121212', // dark grey
      default: 'rgba(18, 18, 18, 0.9)', // very dark grey with transparency
      paper: 'rgba(33, 33, 33, 0.9)', // dark grey with transparency
      card: 'rgba(48, 48, 48, 0.9)', // dark grey with transparency
      bar: 'rgba(30, 136, 229, 0.8)', // vibrant blue with more transparency
    },
    text: {
      primary: '#e0e0e0', // light grey
      secondary: '#b0bec5', // blue grey
      tertiary: '#1e88e5', // vibrant blue
    },
    shadow: {
      accordion: '#424242', // medium grey
    },
    button: {
      main: '#1e88e5', // vibrant blue
      secondary: '#42a5f5', // soft blue
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const abrightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffa726', // light orange
    },
    secondary: {
      main: '#ffcc80', // lighter orange
    },
    background: {
      main: '#ffffff', // white
      default: '#fff8e1', // very light orange
      paper: '#ffecb3', // very light orange
      card: '#ffe0b2', // light orange
      bar: '#ffb74d', // orange
    },
    text: {
      primary: '#212121', // dark grey
      secondary: '#757575', // medium grey
    },
    shadow: {
      accordion: '#bdbdbd', // light grey
    },
    button: {
      main: '#ffb74d', // orange
      secondary: '#ffa726', // light orange
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0d47a1', // dark blue
    },
    secondary: {
      main: '#1976d2', // blue
    },
    background: {
      main: '#121212', // dark grey
      default: 'rgba(28, 28, 28, 0.9)', // very dark grey with transparency
      paper: 'rgba(38, 50, 56, 0.9)', // blue grey with transparency
      card: 'rgba(55, 71, 79, 0.9)', // blue grey with transparency
      bar: 'rgba(13, 71, 161, 0.8)', // dark blue with more transparency
    },
    text: {
      primary: '#e0e0e0', // light grey
      secondary: '#b0bec5', // blue grey
    },
    shadow: {
      accordion: '#757575', // medium grey
    },
    button: {
      main: '#1976d2', // blue
      secondary: '#0d47a1', // dark blue
    },
  },
});

const oldbrightTheme = createTheme({
  palette: {
    mode: 'light', // 'type' is replaced with 'mode'
    primary: {
      main: '#ff8e0d',
    },
    secondary: {
      main: '#ff9012a9',
    },
    background: {
      main: '#ffffff',
      default: '#ffffffa9',
      paper: '#c8c8c8a9',
      card: '#ffe6ce',
      bar: '#ff8c08a9',
    },
    text: {
      primary: '#303030',
      secondary: '#404040'
    },
    shadow: {
      accordion: '#b4b4b4'
    },
    button: {
      main: '#0B6BCB',
      secondary: '#4292e1'
    }
  },
})

const olddarkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#051f57',
    },
    secondary: {
      main: '#11132d',
    },
    background: {
      main: '#ffffff',
      default: '#1e202699',
      paper: '#2d314599',
      card: '#181b32',
      bar: '#10248a'
    },
    text: {
      primary: '#dbdbdb',
      secondary: '#a8a8a8',
    },
    shadow: {
      accordion: '#000000'
    },
    button: {
      main: '#0B6BCB',
      secondary: '#4292e1'
    }
  },
  // Add other theme customizations here
});
// Bright Them

const printTheme = createTheme({ //Enable this theme to make the printable version of the cv
  palette: {
    mode: 'light', // 'type' is replaced with 'mode'
    primary: {
      main: '#ffcc80',
    },
    secondary: {
      main: '#ffb74d',
    },
    background: {
      main: '#33ff00',
      default: '#ffffffa9',
      paper: '#ffffffa9',
      card: '#e2ecff',
      bar: '#7abfffa9',
    },
    text: {
      primary: '#303030',
      secondary: '#404040'
    },
    shadow: {
      accordion: '#b4b4b4'
    },
    card: '#8f8f8ff',
  },
  // Add other theme customizations here
});

export { brightTheme, darkTheme, printTheme };
