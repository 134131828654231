import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./styles/style.css"; 
import App from './App';
import reportWebVitals from './reportWebVitals';
import { useMediaQuery } from 'react-responsive';

import backgroundImage from './assets/background.png';
import Tab from './components/Layout/Tab';
import Background from './components/Layout/Background';

import KeyIcon from '@mui/icons-material/Key';
import { Box, useTheme } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Login = () => {
  const theme = useTheme(); 
  const isMobile = useMediaQuery({query: `(max-width: 650px)`});

  const [isVerified, setIsVerified] = useState(false);

  const checkPw = () => {
    const answer = document.getElementById("password").value;
  
    if (answer === "PriVaCy!24" || answer === "Test") { 
      setIsVerified(true);
    } else {
      alert("Sorry, that's not it");
    }
  };

  console.log("is it mobile?"); 
  console.log(isMobile)

 return (
  <>
  {isVerified ? <App />
    :
   (
    <Background sx={{backgroundImage:  `url(${backgroundImage})`}}>
    <Box sx={{color: theme.palette.text.primary, width: isMobile? "100%" : "50%", height: "500px", margin: "auto", "padding-top": "150px"}}>
    <Tab className = "pw" title="Password"  stayOpen={true} isActive={true} icon={<KeyIcon/>}>
      <form onSubmit={checkPw} style={{margin: "auto", width: isMobile?"100%":"90%", "min-height": "50px", "margin-top": "5px"}}>
        <input id="password" type="password" name="password" style={{width: "60%", height: "100%", padding: "10px", "margin-left": "5px"}}/>
        <button style={{width: "30%", height: "100%", "margin-left": "5px", padding: "10px"}}>
          Unlock CV
        </button>
      </form>
    </Tab>
    <strong style={{color: '#000000', textAlign: "right", float: "right"}}>For privacy reasons, I'm asking for a password.</strong>
    </Box>
    </Background>
  )
  }</>
 )}; 


root.render(
  <React.StrictMode>
    <Login />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
