import { Box } from "@mui/material";

import bgImage from "./../../assets/background.png";

function Background(props){
    return <Box sx={props.sx}>
        {props.children}

    </Box>
}

export default Background; 