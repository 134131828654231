import React, { useState } from 'react'; 
import './App.css';
import { useMediaQuery } from 'react-responsive';
import CssBaseline from '@mui/material/CssBaseline';

// Import experimental extend theme and provider from MUI
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';

// Import Joy UI's CssVarsProvider
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';

import { brightTheme, darkTheme, printTheme } from './styles/theme';

import { Box, Grid } from '@mui/material';

import Tab from "./components/Layout/Tab"; 
import Background from "./components/Layout/Background"; 
import Diplomas from './components/Diplomas';
import Contacts from './components/Contacts';
import Skills from './components/SkillsChrome/Skills'; //Experimental
import NavBar from './components/NavBar';
import Experience from './components/Experience';
import DownloadModal from './components/DownloadModal'; //Download Modal

import profilePic from "./assets/pic.png"; 
import backgroundImage from './assets/background.png';
// Material icons
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import TimelineIcon from '@mui/icons-material/Timeline';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import WorkIcon from '@mui/icons-material/Work';
import ScienceIcon from '@mui/icons-material/Science';

function App() {
  const isMobile = useMediaQuery({query: `(max-width: 650px)`});
  
  const [darkMode, setDarkMode] = useState(true); 

  const toggleDarkMode = () => {
    setDarkMode(!darkMode); 
  }

  const muiTheme = darkMode ? darkTheme : brightTheme; //printTheme; // 

  const [activeTabs, setActiveTabs] = useState({
    picture: true,
    skills: true,
    experience: true,
    timeline: true,
    contacts: true,
    download: true,
  }); 

  const toggleTab = (tabName) => {
    setActiveTabs(prevActiveTabs => ({
      ...prevActiveTabs,
      [tabName]: !prevActiveTabs[tabName]
    })); 
  }

  return (
     <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialExtendTheme(muiTheme) }}>
      <JoyCssVarsProvider>
        <CssBaseline enableColorScheme />
        <div className="App">
          <Background sx={{bgcolor: muiTheme.palette.background.main, backgroundImage:  `url(${backgroundImage})`}}>
          <Box sx={{width: (muiTheme==printTheme)?1:isMobile?1:.75, m: "auto"}}>
            <Grid container spacing={2}>
              <Grid item xs={isMobile?12:3} spacing={2}>
                <Grid item xs={12} >
                  <Tab id = "picture" title="Picture.exe" onClose={() => {toggleTab("picture")}} toggleTab={toggleTab} isActive={activeTabs.picture} icon={<AccountBoxIcon/>} sx={{width: "500px", height: "500px"}}>
                    <div id="photo" >
                      <img src={profilePic}/>
                    </div>
                  </Tab>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                  <Tab id = "contacts" title="Contacts.exe" toggleTab={toggleTab} isActive={activeTabs.contacts} icon={<ContactEmergencyIcon/>}>
                    <Contacts />
                  </Tab>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                  <Tab id = "timeline" title="Diplomas.exe" onClose={() => {toggleTab("picture")}}  toggleTab={toggleTab} isActive={activeTabs.timeline} icon={<ScienceIcon/>}>
                    <Diplomas />
                  </Tab>
                </Grid>
              </Grid>
              <Grid item xs={isMobile?12:9} alignItems="stretch" spacing={2}>
                <Grid item xs={12}>
                  <Tab id = "skills" title="Skills.exe" onClose={() => {toggleTab("skills")}} toggleTab={toggleTab} isActive={activeTabs.skills} icon={<WorkIcon/>}>
                    <Skills> </Skills>
                  </Tab>
                </Grid>
                <Grid item xs={12} marginTop={2}>
                  <Tab id = "experience" title="Experience.exe" toggleTab={toggleTab} isActive={activeTabs.experience} icon={<TimelineIcon/>}>
                    <Experience />
                  </Tab>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{height: '100px'}}/>
          <NavBar 
            activeTab = {activeTabs} 
            toggleTab={toggleTab} 
            toggleDarkMode={toggleDarkMode}
            icons={{
              timeline: <TimelineIcon/>, 
              experience: <ScienceIcon/>, 
              skills: <WorkIcon/>, 
              contacts: <ContactEmergencyIcon/>,
               picture: <AccountBoxIcon/>}}
            />
          </Background>
        </div>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default App;
