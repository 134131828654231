import React, { useState } from 'react';
import { useTheme  } from '@mui/material/styles';
import { Box, Paper, Tabs, Tab, Button, Switch, styled } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { Select, Option } from "@mui/joy";

import DarkModeIcon from '@mui/icons-material/DarkMode';
import DarkMode from '@mui/icons-material/DarkMode';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.background.bar,
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));
function NavBar({activeTab, activeTabs, toggleTab, icons, toggleDarkMode, darkMode}){
    const theme = useTheme(); 
    const isMobile = useMediaQuery({query: `(max-width: 760px)`});

    const handleChange = (event, newValue) => {
        toggleTab(newValue);
    };

    const content = [
        {icon: icons.picture, active: activeTab["picture"], element: "picture"},
        {icon: icons.experience, active: activeTab["experience"], element: "experience"},
        {icon: icons.timeline, active: activeTab["timeline"], element: "timeline"},
        {icon: icons.contacts, active: activeTab["contacts"], element: "contacts"},
        {icon: icons.skills, active: activeTab["skills"], element: "skills"},
    ]
    return (
        <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            left: 0, 
            right: 0, 
            bgcolor: theme.palette.background.card,
            display: 'flex',
            justifyContent: 'center',
            width: "100%",
            zIndex: 9999,
            
        }}>
            <Tabs
                value={activeTabs}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                aria-label="icon tabs example"
                sx={{
                    '.MuiTabs-flexContainer': {
                        justifyContent: 'center',
                    },
                    padding: "5px",
                    zIndex: 1100,
                    width: isMobile?0.8:0.5,
                    "box-shadow": "1 2px 3px 4px rgba(0,0,0,.5) !important"
                }}
                
            >
                {content.map((tab, index) => (
                    <Tab icon={tab.icon} 
                    sx={{
                        bgcolor: tab.active?theme.palette.background.bar:null,
                        "opacity": "1 !important",
                        "border-radius": "5px",
                        boxShadow: tab.active?2:null,
                        color: theme.palette.text.primary,
                        "min-width": "5px",
                        width: isMobile ? .15 : "auto",
                        height: "auto",
                        margin: "5px",
                        zIndex: 1100

                    }} onClick={() => toggleTab(tab.element)} />
                ))}

                
            </Tabs>
            <MaterialUISwitch sx={{"margin-top": "auto", "margin-bottom": "auto"}} defaultChecked={true} onChange={toggleDarkMode}/>
        </Paper>
    );
}

export default NavBar;