import * as React from 'react';

import { 
  AccordionGroup,
  Accordion, accordionClasses , 
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
  Grid,
  Card, 
  CardContent
} from "@mui/joy"
import { useTheme, Box } from '@mui/material';

import TextCard from './Layout/TextCard';

import {ReactComponent as Lua} from "./SkillsChrome/InternalSkillsTabs/icons/lua.svg";
import CodeIcon from '@mui/icons-material/Code';
import ModelingIcon from '@mui/icons-material/ViewInAr';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import BadgeIcon from '@mui/icons-material/Badge';

import profilePic from "./../assets/portrait.png";
import swords_pic from "./../assets/modeling_swords.png";
import tricorne_pic from "./../assets/modeling_tricorne.png";

const content = [
  {
    icon: <CodeIcon sx={{"margin-right": "10px"}}/>,
    title: "General Programming Experience",
    content:  <><div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: '1', marginRight: '20px' }}>
        <p>
          {/*<img src={profilePic} alt="Computer 1" style={{ width: '150px', height: 'auto', float: 'left', marginRight: '20px' }} />*/}
          My experience with computers dates back to my very early age. Despite growing up in the countryside, I always had an interest in computers. Whenever I wasn't outdoors, I was on my computer exploring new things to do, trying new techniques, and practicing my skills in different domains.
        </p>
        <p>
          I started programming at the age of 14, with a desire to create my first website using pure HTML & CSS. Additionally, I began object-oriented programming around the same age while playing Minecraft and ROBLOX, both of which use Lua. ROBLOX provided an excellent environment for learning Lua, offering advanced tools and a great compiler. It allowed me to create games or experiments, such as mathematical models.
        </p>
        <p>
          Later on, I learned other programming languages like JavaScript, Java, and C++, either for personal projects or as part of my studies.
        </p>
      </div>
      {/*<img src={profilePic} alt="Computer 2" style={{ width: '150px', height: 'auto', float: 'right' }} />*/}
    </div>
    <TextCard title="tl;dr" content="I started programming early in age at around 14 with HTML, CSS, JS on the web, and Lua on the platforms ROBLOX & Minecraft."/>
    </>
  },
  {
    icon: <BadgeIcon sx={{"margin-right": "10px"}}/>,
    title: "Internship among Capital Group Companies",
    content: <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ flex: '1', marginRight: '20px' }}>
      <p>
        Between 2022 and 2023, I completed a 10-month long internship at the Geneva Branch of the Capital Group Companies. During this internship, I took on various roles in IT, including:
      </p>
      <ul>
        <li>Web development for a financial-oriented application (both mobile application and website)</li>
        <li>Network-oriented role during an office move in Geneva</li>
        <li>Data-oriented role afterwards</li>
      </ul>
      {/*<img src={profilePic} alt="Profile" style={{ width: '200px', height: 'auto', float: 'right' }} />*/}
      <p>
        This internship provided me with valuable knowledge and experience in different domains and software. I am grateful for their support during my time there and had a very pleasant and enriching experience.
      </p>
      
      <TextCard title="tl;dr" content="I did an internship in which I've learnt tons of new skills!"/> 
      
    </div>
  </div>
  },
  {
    icon: <ModelingIcon sx={{"margin-right": "10px"}}/>,
    title: "Self Employed Modeler",
    content: <Box sx={{ padding: '20px' }}>
    <Typography variant="h4" component="h2" gutterBottom>
      My 3D Modeling Experience
    </Typography>
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="body1" paragraph>
          I've been Modeling 3D Models for Video Games and Graphical renders since February 2020. As an active developer on the platform ROBLOX as a programmer, I wanted to participate in a contest to become an official modeler for the platform in the case of a Beta program. In this way, I was motivated to learn how to model with a friend. That's when I downloaded the useful softwares (Blender, Substance..) and started intensively learning by myself, with the help of blogs, youtube videos...and random articles.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={{ position: 'relative' }}>
          <img src={swords_pic} alt="Swords" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
          <Typography variant="caption" sx={{ position: 'absolute', bottom: 0, left: 0, padding: '4px', backgroundColor: 'rgba(0, 0, 0, 0.6)', color: 'white' }}>
            Swords Model
          </Typography>
        </Box>
      </Grid>
    </Grid>
    <TextCard title="" content={
      <Typography textColor="inherit" textAlign="center">"Since 2020, I earn most of my income (enough to make a living) from 3D Modeling on ROBLOX."</Typography>
    }/> 
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Box sx={{ position: 'relative' }}>
          <img src={tricorne_pic} alt="Tricorne" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
          <Typography variant="caption" sx={{ position: 'absolute', bottom: 0, left: 0, padding: '4px', backgroundColor: 'rgba(0, 0, 0, 0.6)', color: 'white' }}>
            Tricorne Model
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1" paragraph>
        I use my modeling skills for various needs since then such as architectural renderings, using them on other platforms such as making a metaverse on Decentraland during my internship...Interactive websites...There are countless use cases!
        </Typography>
        <Typography variant="body1" paragraph>
          I mostly model low polygons models, which means models with lesser geometrical points (and so data). They are much more easy to process, animate and use for computing them in softwares, games. I can also make what we call "high-poly" models for rendering and demonstrative structures.
        </Typography>
      </Grid>
    </Grid>
  </Box>
  },
  {
    icon: <CandlestickChartIcon sx={{"margin-right": "10px"}}/>,
    title: "Financial experience",
    content: "Since the year 2021 I have been involved in finance at a personal level. As I earnt a liveable income out of different projects I was pursuing (most of it being on ROBLOX), I wanted to fructify it. I opened a personal brokerage account and started investing. Since then, after dozens and dozens of different investments, playing between day-trading and long-term strategies, I have developed a common knowledge of finance. It is now one of my interests, and I read related articles daily as well as common strategy adjustments of my savings. Furthermore, my experience in the Capital Group companies from 2022 to 2023, although only in the I.T department helped grow my interest in the private equity, bonds, and stock market domain. In the context of finance, I have been using programming as well for using, and making tools on various platforms such as TradingViews, or make data models in js or Python."
  }
]

const CustomAccordion = (props) => {
  const theme = useTheme(); 
  return (
    <Accordion>
        <AccordionSummary>
          
          <Box sx={{color: theme.palette.text.primary, textAlign: "left !important", float: "left" }}>{props.icon}{props.title}</Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{color: theme.palette.text.secondary, textAlign: 'left'}}>
            {props.content}
          </Box>
        </AccordionDetails>
      </Accordion>
  )
}
export default function AccordionStylingExpansion() {
  const theme = useTheme(); 

  return (
    <AccordionGroup
      sx={{
        padding: '5px',
        bgcolor: theme.palette.background.paper,
        [`& .${accordionClasses.root}`]: {
          marginTop: '0.5rem',
          transition: '0.2s ease',
          '& button:not([aria-expanded="true"])': {
            transition: '0.2s ease',
            paddingBottom: '0.625rem',
          },
          '& button:hover': {
            background: 'transparent',
          },
        },
        [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
          bgcolor: theme.palette.background.default,
          borderRadius: 'md',
          borderBottom: '3px solid',
          borderColor: theme.palette.shadow.accordion
        },
        '& [aria-expanded="true"]': {
          boxShadow: (theme) => `inset 0 -1px 0 ${theme.vars.palette.divider}`,
        },
      }}
    >
      {content.map((section) => (
        <CustomAccordion title={section.title} content={section.content} icon={section.icon}/>
      ))}
    </AccordionGroup>
  );
}