import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import { useTheme } from '@mui/material';
import { Download } from '@mui/icons-material';

import Tab from './Layout/Tab';

import englishCV from "./../assets/cv_english.pdf";
import frenchCV from "./../assets/cv_french.pdf"; 

import bg from "./../assets/background.png";

export default function DownloadModal() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button variant="solid" color="primary" onClick={() => setOpen(true)} sx={{padding: "10px", "margin": "10px", width: "80%"}}>
        Download CV
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          color='plain'
          sx={/*(theme) => ({
            [theme.breakpoints.only('xs')]: {
              top: 'unset',
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: 'none',
              maxWidth: 'unset',
            },
          })*/{
            bgcolor: theme.palette.background.default,
            padding: 0,
            
          }}
        >
          <Tab className = "pw" title="Download printable CV" isActive={true} callBack = {setOpen} icon={<Download/>} sx={{bgcolor: theme.palette.background.default}}>
          <Typography id="nested-modal-description" sx={{m: 1, color: theme.palette.text.primary}}>
            A PDF version of my Curriculum Vitae will be downloaded on your device.
            <div>It will have a limited amount of image and colors in order to be printable.</div>
            <div>There is both a french and an english version of my cv.</div>
          </Typography>
          <Box
            sx={{
              m: 1,
              display: 'flex',
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row-reverse' },
            }}
          >
            <Button 
            variant="solid" 
            color="primary" 
            onClick={() => {
                const a = document.createElement('a'); 
                a.href = englishCV; 
                a.download = "cv"; 
                a.click(); 
              }}>
              Download English CV
            </Button>
            <Button 
            variant="solid" 
            color="primary" 
            onClick={() => {
                const a = document.createElement('a'); 
                a.href = frenchCV; 
                a.download = "cv"; 
                a.click(); 
              }}>
              Download French CV
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => {
                setOpen(false);
              }}
              sx={{color: theme.palette.text.secondary}}
            >
              Cancel
            </Button>
          </Box>
          </Tab>
          
        </ModalDialog>
      </Modal>
    </>
  );
}