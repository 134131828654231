import React, { useState } from "react";
import { Tabs } from "@sinm/react-chrome-tabs";
import "@sinm/react-chrome-tabs/css/chrome-tabs.css";
import "@sinm/react-chrome-tabs/css/chrome-tabs-dark-theme.css";
import "./styles.css";

import Programming from "./InternalSkillsTabs/Programming";
import Office from "./InternalSkillsTabs/Office"
import Languages from "./InternalSkillsTabs/languages/Languages"
import Modeling from "./InternalSkillsTabs/3dModeling"

import { ReactSVG } from "react-svg";

import modeling_icon from "./icons/3d.png"; 
import translate_icon from "./icons/translate.png"; 
import programming_icon from "./icons/programming.png"; 
import office_icon from "./icons/desk.png";
import { useTheme } from "@mui/material";

const defaultTabs = [
    { id: "Prog", favicon: programming_icon, title: "Programming", active: true, body: <Programming/>},
    { id: "Office", favicon: office_icon, title: "Office", active: false, body: <Office/> },
    { id: "Random", favicon: modeling_icon, title: "Modeling", active: false, body: <Modeling /> },
    { id: "Lang", favicon: translate_icon, title: "Languages", active: false, body: <Languages/> },
]

let _id = 1;
function uniqId() {
  return _id++;
}

function createNewTab() {
  const id = uniqId();
  return {
    id: `tab-id-${id}`,
    title: `New Tabs ${id}`,
    favicon: id % 2 ? modeling_icon : test
  };
}

export default function Skills() {
  const theme = useTheme(); 
  const [tabs, setTabs] = useState(defaultTabs);

  const [darkMode, setDarkMode] = useState(true);

  const addTab = () => {
    setTabs([...tabs, createNewTab()]);
  };

  const active = (id) => {
    setTabs(tabs.map((tab) => ({ ...tab, active: id === tab.id })));
  };

  const close = (id) => {
    if(id == "Prog" || id == "Lang" || id == "Office" || id == "Random"){ return; }
    setTabs(tabs.filter((tab) => tab.id !== id));
  };

  const reorder = (tabId, fromIndex, toIndex) => {
    const beforeTab = tabs.find((tab) => tab.id === tabId);
    if (!beforeTab) {
      return;
    }
    let newTabs = tabs.filter((tab) => tab.id !== tabId);
    newTabs.splice(toIndex, 0, beforeTab);
    setTabs(newTabs);
  };

  const closeAll = () => setTabs(defaultTabs);

  return (
    <div sx={{"min-height": 400, padding: "10px"}}>
      <Tabs
        darkMode={theme.palette.mode=="dark"}
        onTabClose={close}
        onTabReorder={reorder}
        onTabActive={active}
        tabs={tabs}
      />
      
      {tabs.map((tab) => {
        if(tab.active){
            return tab.body; 
        }
      })}
    </div>
  );
}

/*Add tab button could be added
<button onClick={addTab}>Add Tab</button>
      <button onClick={closeAll}>Close All</button>
*/