import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HomeIcon from '@mui/icons-material/Home';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useTheme } from '@mui/material';

import DownloadModal from './DownloadModal';

function CustomAvatar(props){
    const theme = useTheme(); 
    return <Avatar sx={{color: theme.palette.text.primary, bgcolor: theme.palette.background.default}}>
        {props.children}
    </Avatar>
}

function CustomListItem(props){
    return (
    <ListItem>
        <ListItemAvatar>
        <CustomAvatar>
            {props.icon}
        </CustomAvatar>
        </ListItemAvatar>
        <ListItemText primary={props.title} secondary={<Typography>
            {props.children}
        </Typography>} />
    </ListItem>
    )
}

export default function Contacts() {
  return (
    <List sx={{ width: '100%', maxWidth: 450, bgcolor: 'rgba(26, 26, 26, 0.1)' }}>
        <CustomListItem icon={<LocalPhoneIcon/>} title="Phone">
            <a href="tel: +33785940335">+33 7 85 94 03 35</a>
        </CustomListItem>
        <CustomListItem icon={<AlternateEmailIcon/>} title="Email">
            <a href="mailto:btecgael@gmail.com">btecgael@gmail.com</a>
        </CustomListItem>
        <CustomListItem icon={<HomeIcon/>} title="Address">
            74400 Chamonix Mont-Blanc, France
        </CustomListItem>
        <CustomListItem icon={<LinkedInIcon/>} title="Linkedin">
            @GaelMonzio
        </CustomListItem>
        <DownloadModal />
    </List>
  );
}