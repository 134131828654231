import * as React from 'react'; 
import {useMediaQuery} from 'react-responsive';
import { ReactSVG } from 'react-svg';

import { Box, Paper, useTheme, styled } from '@mui/material';

import { Masonry } from '@mui/lab';

import InteractiveCard from '../../Layout/InteractiveCard';

import {ReactComponent as Blender} from "./icons/softwares/blender.svg";
import {ReactComponent as SubstancePainter} from "./icons/softwares/substancepainter.svg"; 
import {ReactComponent as Substance} from "./icons/softwares/substance.svg"

const softwares = [
  {icon: <Blender width="auto" height="auto"/>, label: "Blender", desc: "Open source 3D Modeling, rendering software."},
  {icon: <SubstancePainter width="auto" height="auto"/>, label: "Substance Painter", desc: "Part of the Adobe suite, texture-editing software for 3D Models and scenes."},
  {icon: <Substance width="auto" height="auto"/>, label: "Substance", desc: "Part of the Adobe Suite Substance software (including Substance painter)."},
]

export default function Modeling(){
    const theme = useTheme();
    const isMobile = useMediaQuery({query: `(max-width: 760px)`});

    return <Paper sx={{padding: "10px", height: "auto", bgcolor: theme.palette.background.paper}}>
    <h1 style={{color: theme.palette.text.primary}}>3D Modeling Skills</h1>
    <Masonry columns={isMobile?2:6} spacing={2} sx={{width: "95%", margin: "auto", alignContent: "center"}}>
            {softwares.map((item, index) => (
                <InteractiveCard icon={item.icon} label={item.label} desc={item.desc} />
            ))}
    </Masonry>
</Paper>
}
