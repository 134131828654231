import * as React from 'react'; 
import {useMediaQuery} from 'react-responsive';
import { Box, Paper, useTheme, styled, Stack } from '@mui/material';
import { Masonry } from '@mui/lab';

import InteractiveCard from '../../Layout/InteractiveCard';

import {ReactComponent as OneNote} from "./icons/softwares/oneNote.svg";
import {ReactComponent as Excel} from "./icons/softwares/excel.svg";
import {ReactComponent as Word} from "./icons/softwares/word.svg";
import {ReactComponent as Outlook} from "./icons/softwares/outlook.svg";
import {ReactComponent as PowerPoint} from "./icons/softwares/powerPoint.svg";
import {ReactComponent as Photoshop} from "./icons/softwares/ps.svg";
import {ReactComponent as Eclipse} from "./icons/softwares/eclipse.svg";
import {ReactComponent as Git} from "./icons/softwares/git.svg";
import {ReactComponent as Vsc} from "./icons/softwares/vsc.svg";
import {ReactComponent as Github} from "./icons/softwares/github.svg";
import {ReactComponent as Processing} from "./icons/softwares/processing.svg";
import {ReactComponent as IntelliJ} from "./icons/softwares/intelliJ.svg";
import {ReactComponent as PowerBI} from "./icons/softwares/powerbi.svg";
import {ReactComponent as MySQL} from "./icons/softwares/mysql.svg";
import {ReactComponent as Informatica} from "./icons/softwares/informatica.svg";


const softwares = [
    {icon: <OneNote width="auto" height="auto"/>, label: "OneNote", desc: "A smart notepad software", type: "Utility"},
    {icon: <Excel width="auto" height="auto"/>, label: "Excel", desc: "A smart calculation software", type: "Utility"},
    {icon: <Outlook width="auto" height="auto"/>, label: "Outlook", desc: "An email-based software", type: "Utility"},
    {icon: <PowerPoint width="auto" height="auto"/>, label: "PowerPoint", desc: "A diapositive / slides based software", type: "Utility"},
    {icon: <Word width="auto" height="auto"/>, label: "Word", desc: "A text-editing software", type: "Utility"},
    {icon: <Photoshop width="auto" height="auto"/>, label: "Photoshop", desc: "A smart photo and image editor", type: "Graphics"},
    {icon: <Eclipse width="auto" height="auto"/>, label: "Eclipse", desc: "", type: "Dev"},
    {icon: <Git width="auto" height="auto"/>, label: "Git Bash", desc: "", type: "Dev"},
    {icon: <Vsc width="auto" height="auto"/>, label: "Visual Studio Code", desc: "", type: "Dev"},
    {icon: <Github width="auto" height="auto"/>, label: "Github", desc: "", type: "Dev"},
    {icon: <Processing width="auto" height="auto"/>, label: "Processing", desc: "", type: "Dev"},
    {icon: <IntelliJ width="auto" height="auto"/>, label: "IntelliJ Idea", desc: "", type: "Dev"},
    {icon: <PowerBI width="auto" height="auto"/>, label: "Power BI", desc: "", type: "Data"},
    {icon: <MySQL width="auto" height="auto"/>, label: "MySQL", desc: "", type: "Data"},
    {icon: <Informatica width="auto" height="auto"/>, label: "Informatica suite", desc: "", type: "Data"},
];

function Office2(){
    const theme = useTheme(); 
    const isMobile = useMediaQuery({query: `(max-width: 760px)`});

    return <Paper sx={{padding: "10px", height: "auto", bgcolor: theme.palette.background.paper}}>
        <h1 style={{color: theme.palette.text.primary}}>Office Softwares</h1>
        <Masonry columns={isMobile?2:"auto"} spacing={2} sx={{width: "95%", margin: "auto", alignContent: "center"}} >
                {softwares.map((item, index) => (
                    <InteractiveCard icon={item.icon} label={item.label} desc={item.desc} type={item.type}/>
                ))}
        </Masonry>
    </Paper>
};

export default function Office(){
    const theme = useTheme(); 
    const isMobile = useMediaQuery({query: `(max-width: 760px)`});

    return (
        <Paper sx={{padding: "10px", height: "auto", bgcolor: theme.palette.background.paper}}>
        <h1 style={{color: theme.palette.text.primary}}>Office Softwares</h1>
        <Masonry columns={isMobile?2:"auto"} spacing={2} sx={{width: "95%", margin: "auto", alignContent: "center", alignItems: "flex-start"
    }} >
                {softwares.map((item, index) => (
                    <InteractiveCard icon={item.icon} label={item.label} desc={item.desc} type={item.type}/>
                ))}
        </Masonry>
        </Paper>
    )
};
