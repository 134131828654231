import * as React from 'react'; 
import {useMediaQuery} from 'react-responsive';

import { Box, Paper, Typography, Grid, Stack, useTheme } from '@mui/material';
import CircularProgress from '@mui/joy/CircularProgress';
import PropTypes from 'prop-types';

import InteractiveCard from '../../../Layout/InteractiveCard';

import fr from "./france.png"; 
import uk from "./uk.png";
import it from "./italy.png";
import es from "./spanish.png";
import ru from "./russian.png";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', padding: "10px", justifyContent: "center"}}>
      <CircularProgress sx={{
        '--CircularProgress-trackThickness': "15px",
        "--CircularProgress-progressThickness": "9px",
        '--CircularProgress-size': '100px',
        backgroundImage: `url(${props.flag})`, 
        backgroundSize: "100%", 
        backgroundPositionY: "-50%"
        }} 
        color='primary'
        variant="solid" 
        determinate={true} 
        value={props.value}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >

      </Box>
      
    </Box>
    
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function Languages(){
    const theme = useTheme(); 
    const isMobile = useMediaQuery({query: `(max-width: 760px)`});

    const languages = [
        { name: 'French', proficiency: 100, flag: fr},
        { name: 'Italian', proficiency: 90, flag: it},
        { name: 'English', proficiency: 90, flag: uk },
        { name: 'Spanish', proficiency: 50, flag: es },
        { name: 'Russian', proficiency: 5, flag: ru}
        // Add more languages as needed
    ];

    return <Paper sx={{padding: "10px", height: "auto", bgcolor: theme.palette.background.paper}}>
        <h1 style={{color: theme.palette.text.primary}}>Spoken Languages</h1>
        <Stack direction={isMobile?"column":"row"} spacing={1} justifyContent="center">
          {languages.map((language, index) => (
            <InteractiveCard label={`${Math.round(language.proficiency)}%`} desc={language.name}>
              <CircularProgressWithLabel value={language.proficiency} size={"auto"} flag={language.flag}/>
            </InteractiveCard> 
          ))
          }
        </Stack>
    </Paper>
}
