import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

const steps = [
  {
    year: 2019,
    label: 'Baccalauréat Général Scientifique',
    description: `General Scientific Bachelor in France at Lycée Roger Frison-Roche (Highschool) in Chamonix Mont-Blanc`,
  },
  {
    year: 2022,
    label: 'Cambridge English Advanced 2022',
    description: `Cambridge diploma for C1 level in the english language`
  },
  {
    year: 2022,
    label: 'BTEC Higher National Certificate Computing 2022',
    description: `BTEC Computer 2022`
  },
  {
    year: 2023,
    label: 'BTEC Higher National Diploma Computing 2023',
    description: `BTEC Computing 2023`
  }
]

export default function ImageTimeline() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: 450, padding: "20px" }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.year}>
            <StepLabel
              
              icon={
                <Box sx={{ display: 'inline-flex', marginLeft: '-12px' /* Adjust as necessary */ }}>
                  <Typography
                    sx={{
                      width: '48px',
                      height: '24px',
                      bgcolor: theme.palette.background.card,
                      color: theme.palette.text.secondary,
                      borderRadius: '10%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {step.year}
                  </Typography>
                </Box>
              }
              
            >
              <Typography sx={{color: theme.palette.text.primary, fontWeight: 'bold',}}>{step.label}</Typography>
            </StepLabel>
            <StepContent>
              <Typography sx={{color: theme.palette.text.secondary}}>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={index === steps.length - 1?handleReset:handleNext}
                    sx={{ mt: 1, mr: 1, bgcolor: theme.palette.button.main, "background-color#hover": theme.palette.button.secondary,color: '#fff'}}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1, color: theme.palette.button.main }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/*activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )*/}
    </Box>
  );
}