import React, {useState, useEffect} from 'react'; 
import { useTheme } from '@mui/material/styles';
import {useMediaQuery} from 'react-responsive';

import {Box, Paper, useThemeProps} from '@mui/material';

function Tab(props){
    const theme = useTheme(); 
    const [animationState, setAnimationState] = useState(props.isActive ? 'open' : 'closed');

    useEffect(() => {
      if (props.isActive && animationState === 'closed') {
        setAnimationState('opening');
      } else if (!props.isActive && animationState === 'open') {
        setAnimationState('closing');
      }
    }, [props.isActive, animationState]);
  
    useEffect(() => {
      if (animationState === 'opening') {
        setTimeout(() => setAnimationState('open'), 500); // Match your animation duration
      }
      if (animationState === 'closing') {
        setTimeout(() => setAnimationState('closed'), 500); // Match your animation duration
      }
    }, [animationState]);
  
    const handleClose = () => {
      if (!props.stayOpen) {
        setAnimationState('closing');
        if (typeof props.callBack === 'function') {
          props.callBack(false);
        } else {
          setTimeout(() => {
            props.toggleTab(props.id);
          }, 500); // Duration should match the CSS transition
        }
      }
    };
  
    let frameClass = `${props.className} frame`;
    if (animationState === 'opening' || animationState === 'closing') {
      frameClass += ` ${animationState}`;
    }
  
    return (
      <Paper elevation={3} 
           sx={{ 
            display: animationState !== 'closed' ? 'initial' : 'none', 
            boxShadow: 3,
            ...props.sx
            }}>
        <div id={props.id} style={{'background-color': theme.palette.background.default}} className={frameClass}>
            <div class="title-bar" style={{'background-color': theme.palette.background.bar}}>
                {props.icon}
                <span class="title" style={{"text-align": "left", "margin-right": "auto", "margin-left": "5px"}}>{props.title}</span>
                <div class="window-controls">
                    <div class="control minimize"></div>
                    <div class="control resize"></div>
                    <div class="control close" onClick={handleClose}></div>
                </div>
            </div>
            <div class="content" style={{"background-color": theme.palette.background.default, color: theme.palette.text.primary, }}>
                {props.children}
            </div>
        </div>
      </Paper>
    );
}

export default Tab; 
