import * as React from 'react';

import { useTheme } from "@mui/material";
import { 
    Card, 
    CardContent,
    Typography 
} from "@mui/joy"

function TextCard(props){
    const theme = useTheme(); 

    return<Card variant="soft" sx={{width: "100%", float: "left", bgcolor: theme.palette.background.card, color: theme.palette.text.secondary}}>
    <CardContent>
      <Typography level="title-md" textColor="inherit">
        {props.title}
      </Typography>
      <Typography textColor="inherit">{props.content}</Typography>
    </CardContent>
  </Card>
}

export default TextCard; 